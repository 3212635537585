<template>
  <div class="container-fluid p-0">
    <!-- begin::Loading -->
    <div v-if="isMounted === false || isLoading" class="loading-container">
      <div class="loading-backdrop" />
      <div class="loading-block">
        <div class="blockui ml-auto mr-auto w-100">
          <span class="float-left">Gathering data...</span>
          <span class="spinner spinner-primary ml-auto" />
        </div>
      </div>
    </div>
    <!-- end::Loading -->

    <!-- begin::Error alert -->
    <template v-else-if="isErrorActive">
      <error-translation-alert
        ref="errorTranslationAlert"
        :error="activeError"
        class="shadow"
      />
    </template>
    <!-- end::Error alert -->

    <!-- begin::Content -->
    <template v-else>
      <!-- begin::Card - menu -->
      <div class="row">
        <div class="col">
          <div class="card card-custom gutter-b">
            <div class="card-header pt-3">
              <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">Settings</h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1">Update notification settings of this company.</span>
              </div>
            </div>

            <div class="card-body py-0">
              <div class="row">
                <div class="col-12 col-md-auto">
                  <ul class="nav nav-tabs nav-tabs-line border-bottom-0 pt-3" role="tablist">
                    <li class="nav-item mr-14">
                      <router-link :to="{ name: '/notifications/settings/general' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
                        <span class="nav-text">General</span>
                      </router-link>
                    </li>

                    <li class="nav-item mr-14">
                      <router-link :to="{ name: '/notifications/settings/advanced' }" class="nav-link font-weight-bold border-2 pb-6 mx-0" data-toggle="tab" active-class="active">
                        <span class="nav-text">Advanced</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end::Card - menu -->

      <!-- begin::Router view -->
      <transition name="fade-in-up">
        <router-view
          ref="router-view"
          :notifications="notifications"
        />
      </transition>
      <!-- end::Router view -->
    </template>
    <!-- end::Content -->
  </div>
</template>

<script>
import { errorComponentMixin } from '@vedicium/core-vue';

import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [errorComponentMixin],
  components: {
    errorTranslationAlert,
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      notifications: {
        types: [],
        methods: [],
      },
    };
  },
  async mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Notifications' }, { title: 'Settings' }]);

    // Get notification types
    this.$set(this, 'isLoading', true);
    try {
      const response = await this.$ws.get('/v2/notifications/types');
      this.$set(this, 'notifications', {
        types: response.types || [],
        methods: response.methods || [],
      });
    } catch (e) {
      this.$errors.handle(e, { component: this, ui_element: false });
      console.error(e);
      return;
    } finally {
      this.$set(this, 'isLoading', false);
      this.$set(this, 'isMounted', true);
    }
  },
};
</script>
